import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faPaperPlane,
	faBan,
	faPhone,
	faPhoneAlt,
	faClock,
	faMicrophone,
	faDesktop,
	faQuestion,
	faUserMinus,
	faCog,
} from '@fortawesome/free-solid-svg-icons'
import ConfirmTechMatch from '../../Common/ConfirmTechMatch'
import { openNotificationWithIcon, callOnPhone, getParticipantDetails } from '../../../utils'
import mixpanel from 'mixpanel-browser'
//import { endMeeting } from '../../../services/MeetingServices'
import UpdateJobModel from '../../Common/UpdateJobModel'
import { MAIN_APP_URL, TOOLTIP_MSG_FOR_REMOTE_BTN, TOOLTIP_MSG_FOR_ENABLE_REMOTE_BTN } from '../../../constants'
import TechnicianHowToInformation from '../../Common/TechnicianHowToModal'
import TechnicianKickOutControls from '../../Common/TechnicianKickOutControls'
import '../../../css/style.css'
import CloseIcon from '../../../assets/images/close.png'
import { getTooltipComponent } from '../../ReusableComponents/TooltipComponent'
import { Spin } from 'antd'

import { useJitsiMeet } from '../../../context/jitsiMeetContext'
import VolumeControl from '../../VolumeControl/VolumeControl'


const TechnicianMeetingControls = (props) => {
	const dialInRef = useRef(null)
	const [MainInvitation, setInvitation] = useState(false)
	const [invitedNumber, setInvitedNumber] = useState('')
	const { showIssueButton, setShowIssueButton } = useJitsiMeet()

	const remoteDesktopRef = useRef(null)
	const [disabledCallYourself, setDisabledCallYourself] = useState(false)
	const [disabledCallCustomer, setDisabledCallCustomer] = useState(false)
	const [dataLoaded, setDataLoaded] = useState(false)
	const isScreenShared = false
	const [showTransfterCallLoader, setShowTransfterCallLoader] = useState(false)
	const [showUpdateJobModal, setShowUpdateJobModal] = useState(false)
	// const [isTimerPaused, setIsTimerPaused] = useState(false)
	const [disableStartPauseButton, setDisableStartPauseButton] = useState(false)
	const [inviteStatus, setInviteStatus] = useState(false)
	const [disabledUnmuteCustomer, setDisabledUnmuteCustomer] = useState(false)
	const [disabledRemoteButton, setDisabledRemoteButton] = useState(false)
	const [showTut, setShowTut] = useState(false)
	const [showKickOut, setshowKickOut] = useState(false)
	const [showParticipant, setShowParticipant] = useState([])
	const [addKickparticpant, setAddKickparticpant] = useState([])
	const [isInvitedPhone, setIsInvitedPhone] = useState(false)
	const storedPausedState = JSON.parse(localStorage.getItem(`timerPaused-${props.job.id}`)) === true
	// const [showRemoteToolTip, setShowRemoteToolTip] = useState(false)
	const [remoteBtnToolTipMsg, setRemoteBtnToolTipMsg] = useState('')
	const [unmuteCustomer, setUnmuteCustomer] = useState(false)
	const [offToolTip, setOffToolTip] = useState(true)
	const [isVolumeOpen, setIsVolumeOpen] = useState(false);
	const volumeControlRef = useRef(null);

	const handleVolumeClick = () => {
			setIsVolumeOpen(!isVolumeOpen);
	};

	useEffect(() => {
			const handleClickOutside = (event) => {
					if (volumeControlRef.current && !volumeControlRef.current.contains(event.target)) {
							setIsVolumeOpen(false);
					}
			};

			document.addEventListener('mousedown', handleClickOutside);
			return () => {
					document.removeEventListener('mousedown', handleClickOutside);
			};
	}, []);

	useEffect(() => {
		if (props.job) {
			if (props.job.meeting_pause) {
				// localStorage.setItem(`timerPaused-${props.job.id}`, true);
				localStorage.setItem(`timerPaused-${props.job.id}`, JSON.stringify(true))
				console.log('timerPaused when meeting pause', storedPausedState)
			} else {
				console.log('timerPaused when meeting start', storedPausedState)
				localStorage.setItem(`timerPaused-${props.job.id}`, JSON.stringify(false))
			}
		}
	}, [props.job])

	// This UseEffect will disable the Remote Button on technician side untill meeting is started ~ Jagroop
	useEffect(() => {
		if (!props.job.meeting_start_time) {
			setDisabledRemoteButton(true)
			setRemoteBtnToolTipMsg(TOOLTIP_MSG_FOR_REMOTE_BTN)
			// setShowRemoteToolTip(true)
		} else {
			setDisabledRemoteButton(false)
			setRemoteBtnToolTipMsg(TOOLTIP_MSG_FOR_ENABLE_REMOTE_BTN)
			// setShowRemoteToolTip(false)
		}
	}, [props.job])

	useEffect(() => {
		if (props.jitsiApi != null && MainInvitation) {
			jitsiApiInviteWithPhone()
		}
	}, [invitedNumber, isInvitedPhone])

	useEffect(() => {
		if (props.socket) {
			/**
			 * Socket will call when click on end meeting button
			 * @params = socketData (Type: Object)
			 * @response : Will redirect user to meeting over screen
			 * @author : Karan
			 */
			props.socket.on('Meeting:over', async (socketData) => {
				if (socketData && socketData.jobId && props.job.id && socketData.jobId === props.job.id) {
					if (socketData.userType !== props.user.userType) {
						localStorage.removeItem('customerModalAction')
						localStorage.removeItem('customerResponceBackForTransfer')
						localStorage.removeItem('isBackdropVisible')
						localStorage.removeItem('coBrandingLogoUrl')
						window.location.href = `/meeting-over/${props.user.userType}/${props.job.id}`
					}
				}
			})
		}
	}, [props.socket])

	const handleShowTut = async () => {
		try {
			setShowTut(!showTut)
		} catch (err) {
			console.log('error in handleShowTut')
		}
	}

	/**
	 * Open transfer call modal
	 * @params = ''
	 * @response : Will open transfer call modal
	 * @author : Ridhima
	 */
	const handleTransferCall = async () => {
		setShowTransfterCallLoader(true)
		setShowUpdateJobModal(!showUpdateJobModal)
		// mixpanel code
		mixpanel.identify(props.user.email)
		mixpanel.track('Technician - Click on Transfer call button', { JobId: props.job.id })
		// mixpanel code
		if (dataLoaded) {
			setShowTransfterCallLoader(false)
		}
	}

	/**
	 * Toggle screenshare
	 * @params = ''
	 * @response : Will toggle screenshare
	 * @author : Ridhima
	 */
	const stopShareScreen = () => {
		if (props.jitsiApi != null && isScreenShared) {
			props.jitsiApi.executeCommand('toggleShareScreen')
		}
	}

	/**
	 * call on phone number which added by user
	 * @params : phone_number, extension, type(Customer/Technician/Invite), jitsiApi, setInviteStatus
	 * @response : Jisti api for invite by phone number
	 * @author : Ridhima
	 */
	function jitsiApiInviteWithPhone() {
		console.log('Jisti api for invite by phone number>>>>>>>>')
		const callParams = {
			phone_number: invitedNumber,
			type: 'Invite',
			jitsiApi: props.jitsiApi,
			setInviteStatus: setInviteStatus,
		}
		callOnPhone(callParams)
	}

	const handleParticipantMic = () => {
		if (props.jitsiApi != null) {
			props.jitsiApi.executeCommand('toggleAudio')
		}
	}

	const handleParticipantKickOut = (kickoutArr) => {
		if (props.jitsiApi != null) {
			for (const p in kickoutArr) {
				props.jitsiApi.executeCommand('kickParticipant', kickoutArr[p])
			}
		}
		return true
	}
	const openParticipantKickOut = async () => {
		const participantInfo = await getParticipantDetails(props.jitsiApi, props.user.userType)
		console.log('Jitsi openParticipantKickOut function called')
		const techName = props.job.technician.user.firstName.trim() + ' ' + props.job.technician.user.lastName.trim() + ' ' + '(t)'
		const resultParticipant = participantInfo.filter((e) => e.displayName !== techName)
		setShowParticipant(resultParticipant)
		setshowKickOut(true)
	}
	/**
	 * Call Technician
	 * @params : phone_number, extension, type(Customer/Technician/Invite), jitsiApi, setInviteStatus
	 * @response : Jisti api for invite by phone number
	 * @author : Ridhima
	 */
	const callYourself = () => {
		handleParticipantMic()
		if (props.jitsiApi != null) {
			setDisabledCallYourself(true)
			const callParams = {
				phone_number: props.job.technician.profile.confirmId.phoneNumber,
				type: 'Technician',
				jitsiApi: props.jitsiApi,
				setInviteStatus: setInviteStatus,
			}
			callOnPhone(callParams)
			// mixpanel code
			mixpanel.identify(props.user.email)
			mixpanel.track('Technician - Call himself', { JobId: props.job.id })
			// mixpanel code
		}
	}

	/**

	 * Call customer
	 * @params : phone_number, extension, type(Customer/Technician/Invite), jitsiApi, setInviteStatus
	 * @response : Jisti api for invite by phone number
	 * @author : Ridhima
	 */
	const callCustomer = () => {
		if (props.jitsiApi != null) {
			setDisabledCallCustomer(true)
			const callParams = {
				phone_number: props.job.customer.phoneNumber,
				type: 'Customer',
				jitsiApi: props.jitsiApi,
				setInviteStatus: setInviteStatus,
			}
			props.socket.emit('call-on-phone', { jobId: props.job.id })
			callOnPhone(callParams)
			// mixpanel code
			mixpanel.identify(props.user.email)
			mixpanel.track('Technician - Call customer', { JobId: props.job.id })
			// mixpanel code
		}
	}

	/**
	 * After jisti api success
	 * @response : set usestate variable according to type
	 * @author : Ridhima
	 */
	useEffect(() => {
		// console.log('inviteStatus :::', inviteStatus)
		if (inviteStatus == 'Technician') {
			setDisabledCallYourself(false)
		}
		if (inviteStatus == 'Customer') {
			setDisabledCallCustomer(false)
		}
		if (inviteStatus == 'Invite') {
			setInvitation(false)
			openNotificationWithIcon('success', 'Success', 'User has been invited on phone.')
		}
	}, [inviteStatus])

	useEffect(() => {
		if (props.socket) {
			/**
			 * Socket will call when click on end meeting button
			 * @params = socketData (Type: Object)
			 * @response : Will redirect user to meeting over screen
			 * @author : Karan
			 */
			props.socket.on('Meeting:over', async (socketData) => {
				if (socketData && socketData.jobId && props.job.id && socketData.jobId === props.job.id) {
					if (socketData.userType !== props.user.userType) {
						localStorage.removeItem('customerModalAction')
						localStorage.removeItem('customerResponceBackForTransfer')
						localStorage.removeItem('isBackdropVisible')
						localStorage.removeItem('coBrandingLogoUrl')
						window.location.href = `${MAIN_APP_URL}/meeting-feedback/${props.job.id}`
					}
				}
			})
		}
	}, [props.socket])

	/**
	 * Handle start/pause button functionality
	 * @params = actionType (Start/Pause)
	 * @response : Will start or pause the meeting timer
	 * @author : Karan
	 */
	const handleStartPauseTimer = (actionType) => {
		const isPaused = JSON.parse(localStorage.getItem(`timerPaused-${props.job.id}`)) === true
		console.log('handleStartPauseTimer isPaused', isPaused, actionType)
		const updatedPausedState = !isPaused
		console.log('handleStartPauseTimer updatedPausedState', updatedPausedState)

		localStorage.setItem(`timerPaused-${props.job.id}`, updatedPausedState.toString())

		setDisableStartPauseButton(true)

		const data = {}
		data['jobId'] = props.job.id
		data['userType'] = props.user.userType
		data['total_pause_seconds'] = props.job.total_pause_seconds

		if (actionType === 'pause') {
			props.socket.emit('Pause:timer', data)
			// mixpanel code
			mixpanel.identify(props.user.email)
			mixpanel.track('Technician - pause timer', { Data: data })
			// mixpanel code
		}

		if (actionType === 'start') {
			props.socket.emit('Resume:timer', data)
			// mixpanel code
			mixpanel.identify(props.user.email)
			mixpanel.track('Technician - Start Timer', { Data: data })
			// mixpanel code
		}
		setTimeout(function () {
			setDisableStartPauseButton(false)
		}, 3000)
	}

	/**
	 * function sends socket to backend to unmute customer
	 * @response : sends socket to backend to unmute customer
	 * @author : Manibha
	 */
	const sendSocketToUnmuteCustomer = () => {
		setDisabledUnmuteCustomer(true)
		openNotificationWithIcon('info', 'Info', 'Going to unmute customer.')
		if (props.user) {
			mixpanel.identify(props.user.email)
			mixpanel.track('Customer- Click on Unmute button', { JobId: props.job.id })
		}
		const data = {}
		data['jobId'] = props.job.id
		props.socket.emit('Unmute:Customer', data)
		setTimeout(function () {
			setDisabledUnmuteCustomer(false)
		}, 5000)
	}

	/**
	 * function sends socket to backend to hit zoho at customer's side
	 * @response : sends socket to backend to hit zoho
	 * @author : Manibha
	 */
	// const hitRemoteAtCustomerSide = () => {
	// 	setDisabledRemoteButton(true)
	// 	const data = {}
	// 	data['jobId'] = props.job.id
	// 	data['userClicks'] = props.user.userType
	// 	props.setIsRemoteLoading(true)
	// 	props.socket.emit('EnableRemote:Customer', data)
	// 	// mixpanel code
	// 	mixpanel.identify(props.user.email)
	// 	mixpanel.track('Technician - Enable Customer Remote', { Data: data })
	// 	// mixpanel code
	// 	setTimeout(function () {
	// 		setDisabledRemoteButton(false)
	// 	}, 5000)
	// }

	const hitRemoteAtCustomerSideScreentConnect = () => {
		setDisabledRemoteButton(true)
		const data = {}
		data['jobId'] = props.job.id
		data['userClicks'] = props.user.userType
		props.setIsRemoteLoading(true)
		props.socket.emit('EnableRemoteScreenConnect', data)
		// mixpanel code
		mixpanel.identify(props.user.email)
		mixpanel.track('Technician - Enable Customer Remote', { Data: data })
		// mixpanel code
		setTimeout(function () {
			setDisabledRemoteButton(false)
		}, 5000)
	}

	useEffect(() => {
		if ((props.job.callStartType === 'Phone' || props.job.technician_started_call) && !unmuteCustomer) {
			setUnmuteCustomer(true)
		} else {
			setUnmuteCustomer(false)
		}
	}, [])

	const getTooltip = () => {
		return offToolTip ? (
			<Tooltip id="tooltip" className="toop-tip-design">
				<>
					<p className="head-tooltip-style mt-2">
						Trouble connecting with Phone Audio?
						<span>
							<img src={CloseIcon} className="img-fluid ml-1 cross-image-style" onClick={() => closeTooltip()} />{' '}
						</span>{' '}
					</p>
					<hr></hr>
					<p className="body-tooltip-style mb-2">Click, Unmute Customer.</p>
					<p className="body-tooltip-style mb-2">It will connect customer with computer audio.</p>
				</>
			</Tooltip>
		) : (
			<></>
		)
	}

	const closeTooltip = () => {
		setOffToolTip(false)
	}

	function renderUnmuteCustomerButton(disabled, onClickFunction) {
		return (
			<Col md="2" lg="3" xs="4" className="pb-3 text-center" title="Unmute customer">
				<Row>
					<Col md="12" className="text-center p-0">
						<Button
							className={(disabled ? 'disabled-btn' : '') + ' meeting-btn'}
							onClick={onClickFunction}
							disabled={disabled}
							title="Unmute customer"
						>
							<FontAwesomeIcon icon={faMicrophone} />
						</Button>
					</Col>
					<Col md="12" className="app-link-outer meeting-btn-link text-center p-0">
						<span className="link-span" onClick={onClickFunction} disabled={disabled}>
							Unmute Customer
						</span>
					</Col>
				</Row>
			</Col>
		)
	}

	return (
		<Row>
			{showKickOut && (
				<TechnicianKickOutControls
					showKickOut={showKickOut}
					handleParticipantKickOut={handleParticipantKickOut}
					showParticipant={showParticipant}
					setshowKickOut={setshowKickOut}
					addKickparticpant={addKickparticpant}
					setAddKickparticpant={setAddKickparticpant}
				/>
			)}

			<TechnicianHowToInformation showTut={showTut} handleShowTut={handleShowTut} />
			<Col md="12" className="tech-side-meeting-controlles">
				<Row style={{ justifyContent: 'center' }}>
					{props.enableStartPauseTimerButton && (
						<Col md="2" lg="3" xs="4" className="pb-3 text-center" title="Start/Pause Timer">
							<Row>
								{storedPausedState && (
									<>
										<Col md="12" className="text-center p-0">
											<Button
												className="meeting-btn-resume-btn"
												onClick={() => {
													handleStartPauseTimer('start')
												}}
												disabled={disableStartPauseButton}
											>
												<FontAwesomeIcon icon={faPaperPlane} />
											</Button>
										</Col>
										<Col md="12" className="app-link-outer meeting-btn-link text-center p-0">
											<span
												className="link-span"
												onClick={() => {
													handleStartPauseTimer('start')
												}}
												disabled={disableStartPauseButton}
											>
												Resume
											</span>
										</Col>
									</>
								)}

								{!storedPausedState && (
									<>
										<Col md="12" className="text-center p-0">
											<Button
												className="meeting-btn"
												onClick={() => {
													handleStartPauseTimer('pause')
												}}
												disabled={disableStartPauseButton}
											>
												<FontAwesomeIcon icon={faClock} />
											</Button>
										</Col>
										<Col md="12" className="app-link-outer meeting-btn-link text-center p-0">
											<span
												className="link-span"
												onClick={() => {
													handleStartPauseTimer('pause')
												}}
												disabled={disableStartPauseButton}
											>
												Pause
											</span>
										</Col>
									</>
								)}
							</Row>
						</Col>
					)}
					{unmuteCustomer ? (
						<OverlayTrigger trigger="hover" defaultShow={offToolTip} placement="top" overlay={getTooltip()}>
							{renderUnmuteCustomerButton(disabledUnmuteCustomer, sendSocketToUnmuteCustomer)}
						</OverlayTrigger>
					) : (
						renderUnmuteCustomerButton(disabledUnmuteCustomer, sendSocketToUnmuteCustomer)
					)}

					<Col md="2" lg="3" xs="4" className="pb-3 text-center" title="Invite user">
						<Row>
							<Col md="12" className="text-center p-0">
								<Button
									className="meeting-btn"
									onClick={() => {
										dialInRef.current.click()
									}}
								>
									<FontAwesomeIcon icon={faPaperPlane} />
								</Button>
							</Col>

							<Col md="12" className="app-link-outer meeting-btn-link text-center p-0">
								<span
									className="link-span"
									onClick={() => {
										dialInRef.current.click()
									}}
								>
									Invite
								</span>
							</Col>
						</Row>
					</Col>

					<Col md="2" lg="3" xs="4" className="pb-3 text-center" title="Adjust notification volume">
						<div className="relative" ref={volumeControlRef}>
								<Button
										className="meeting-btn"
										onClick={handleVolumeClick}
								>
										<FontAwesomeIcon icon={faCog} />
								</Button>
								{isVolumeOpen && (
										<div className="volume-control-popup">
												<VolumeControl />
										</div>
								)}
								<div className="app-link-outer meeting-btn-link text-center p-0">
										<span className="link-span">Settings</span>
								</div>
						</div>
				</Col>

					
				</Row>
				<Row style={{ justifyContent: 'center' }}>
					<Col md="3" lg="3" xs="4" className="pb-3 text-center" title="Call yourself on mobile">
						<Row>
							<Col md="12" className="text-center p-0">
								<Button
									className={(disabledCallYourself ? 'disabled-btn' : '') + ' meeting-btn'}
									onClick={() => {
										callYourself()
									}}
									disabled={disabledCallYourself}
									title="Call yourself on phone"
								>
									<FontAwesomeIcon icon={faPhone} />
								</Button>
							</Col>
							<Col md="12" className="app-link-outer meeting-btn-link text-center p-0">
								<span
									className="link-span"
									onClick={() => {
										callYourself()
									}}
									disabled={disabledCallYourself}
								>
									Call Yourself
								</span>
							</Col>
						</Row>
					</Col>

					<Col md="2" lg="3" xs="4" className="pb-3 text-center" title="Call customer on mobile">
						<Row>
							<Col md="12" className="text-center p-0">
								<Button
									className={(disabledCallCustomer ? 'disabled-btn' : '') + ' meeting-btn'}
									onClick={() => {
										callCustomer()
									}}
									disabled={disabledCallCustomer}
									title="Call customer on phone"
								>
									<FontAwesomeIcon icon={faPhoneAlt} />
								</Button>
							</Col>
							<Col md="12" className="app-link-outer meeting-btn-link text-center p-0">
								<span
									className="link-span"
									onClick={() => {
										callCustomer()
									}}
									disabled={disabledCallCustomer}
								>
									Call Customer
								</span>
							</Col>
						</Row>
					</Col>
					<Col md="2" lg="3" xs="4" className="pb-3 text-center" title="Call customer on mobile">
						<Row>
							<Col md="12" className="text-center p-0">
								<Button className={'meeting-btn'} onClick={handleShowTut} title="How to">
									<FontAwesomeIcon icon={faQuestion} />
								</Button>
							</Col>

							<Col md="12" className="app-link-outer meeting-btn-link text-center p-0">
								<span className="link-span" onClick={handleShowTut}>
									How To
								</span>
							</Col>
						</Row>
					</Col>

					<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={getTooltipComponent(remoteBtnToolTipMsg, true)}>
						<Col md="2" lg="3" xs="4" className="pb-3 text-center" title={disabledRemoteButton ? '' : 'Enable Remote'}>
							<Row>
								<Col md="12" className="text-center p-0">
									{props.isRemoteLoading ? (
										<Spin size="small" />
									) : (
										<Button
											className={(disabledRemoteButton ? 'disabled-btn' : '') + ' meeting-btn'}
											onClick={() => {
												hitRemoteAtCustomerSideScreentConnect()
											}}
											disabled={disabledRemoteButton}
											title="Screen Connect"
										>
											<FontAwesomeIcon icon={faDesktop} />
										</Button>
									)}
								</Col>
								<Col md="12" className="app-link-outer meeting-btn-link text-center p-0">
									<span
										className="link-span"
										onClick={() => {
											hitRemoteAtCustomerSideScreentConnect()
										}}
										disabled={disabledRemoteButton}
									>
										Screen Connect
									</span>
								</Col>
							</Row>
						</Col>
					</OverlayTrigger>

				</Row>
				<Row style={{ justifyContent: 'left' }}>
					<Col md="2" lg="3" xs="4" className="pb-3 text-center" title="Kick Out">
						<Row>
							<Col md="12" className="text-center p-0">
								<Button className={'meeting-btn'} onClick={openParticipantKickOut} title="kick out">
									<FontAwesomeIcon icon={faUserMinus} />
								</Button>
							</Col>

							<Col md="12" className="app-link-outer meeting-btn-link text-center p-0">
								<span className="link-span" onClick={openParticipantKickOut}>
									Kick Out
								</span>
							</Col>
						</Row>
					</Col>
					{props.showTransferButton && (
						<Col md="2" lg="3" xs="4" className="pb-3 text-center" title="Transfer call to another technician">
							<Row>
								<Col md="12" className="text-center p-0">
									<Button className="meeting-btn" onClick={handleTransferCall}>
										<FontAwesomeIcon icon={faBan} />
									</Button>
								</Col>
								<Col md="12" className="app-link-outer meeting-btn-link text-center p-0">
									<span className="link-span" onClick={handleTransferCall}>
										Transfer
									</span>
								</Col>
							</Row>
						</Col>
					)}
				</Row>
				{/*props.enableStartPauseTimerButton && (
					<Row style={{ justifyContent: 'center' }}>
						<Col md="2" lg="3" xs="4" className="pb-3 text-center" title="Start/Pause Timer">
							<Row>
								{isTimerPaused && (
									<>
										<Col md="12" className="text-center p-0">
											<Button
												className="meeting-btn"
												onClick={() => {
													handleStartPauseTimer('start')
												}}
											>
												<FontAwesomeIcon icon={faPaperPlane} />
											</Button>
										</Col>
										<Col md="12" className="app-link-outer meeting-btn-link text-center p-0">
											<a
												href="#"
												onClick={() => {
													handleStartPauseTimer('start')
												}}
											>
												Start
											</a>
										</Col>
									</>
								)}
								{!isTimerPaused && (
									<>
										<Col md="12" className="text-center p-0">
											<Button
												className="meeting-btn"
												onClick={() => {
													handleStartPauseTimer('pause')
												}}
											>
												<FontAwesomeIcon icon={faClock} />
											</Button>
										</Col>
										<Col md="12" className="app-link-outer meeting-btn-link text-center p-0">
											<a
												href="#"
												onClick={() => {
													handleStartPauseTimer('pause')
												}}
											>
												Pause
											</a>
										</Col>
									</>
								)}
							</Row>
						</Col>
					</Row>
				)*/}
			</Col>
			<ConfirmTechMatch
				invited={true}
				socket={props.socket}
				jobId={props.job.id}
				dialInRef={dialInRef}
				stopScreenShare={stopShareScreen}
				setInvitation={setInvitation}
				setInvitedNumber={setInvitedNumber}
				remoteDesktopRef={remoteDesktopRef}
				UserType={props.user.userType}
				UserEmail={props.user.email}
				meetingPlatform={props?.job?.meeting_service}
				setIsInvitedPhone={setIsInvitedPhone}
			/>
			<UpdateJobModel
				showUpdateJobModal={showUpdateJobModal}
				setShowUpdateJobModal={setShowUpdateJobModal}
				showTransfterCallLoader={showTransfterCallLoader}
				setShowTransfterCallLoader={setShowTransfterCallLoader}
				setDataLoaded={setDataLoaded}
				job={props.job}
				user={props.user}
				socket={props.socket}
				showHireExpertCheckbox={true}
				showDeclinedReason={true}
				isTransferCall={true}
			/>
		</Row>
	)
}

export default TechnicianMeetingControls
